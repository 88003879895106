import React from 'react'
import Layout from 'components/layout/layout'
import Button, {
  ButtonColor,
  ButtonType,
  ButtonSize,
} from 'components/button/button'

import 'styles/general.scss'
import 'styles/pages/404.scss'

const PageNotFound = () => (
  <Layout is404>
    <section>
      <h1>404</h1>
      <p>Page not found.</p>
      <p className="container-75">
        Bantu is still hard at working building out our black owned business
        directory. Make sure to add your business to have your own page when we
        officially launch!
      </p>
      <section className="signup-container">
        <Button
          href="https://mailchi.mp/e6b7301766b9/bantubizz"
          openNewTab
          className="margin-x-4 text-bold text-uppercase"
          color={ButtonColor.GREEN}
          type={ButtonType.PRIMARY}
        >
          Submit a business!
        </Button>
        <Button
          href="https://mailchi.mp/aba133d3e05e/bantu4u"
          openNewTab
          className="text-bold text-uppercase"
          color={ButtonColor.YELLOW}
          type={ButtonType.PRIMARY}
        >
          Sign up for news!
        </Button>
      </section>
    </section>
  </Layout>
)

export default PageNotFound
